@font-face {
  font-family: 'Helvetica Light';
  src: url(../fonts/Helvetica-Light.woff2) format("woff2"), url(../fonts/Helvetica-Light.woff) format("woff"), url(../fonts/Helvetica-Light.ttf) format("truetype");
  font-weight: 300;
  font-style: normal;
  font-display: swap; }

@font-face {
  font-family: 'Helvetica Bold';
  src: url(../fonts/Helvetica-Bold.woff2) format("woff2"), url(../fonts/Helvetica-Bold.woff) format("woff"), url(../fonts/Helvetica-Bold.ttf) format("truetype");
  font-weight: 700;
  font-style: normal;
  font-display: swap; }

@font-face {
  font-family: 'Helvetica';
  src: url(../fonts/Helvetica.woff2) format("woff2"), url(../fonts/Helvetica.woff) format("woff"), url(../fonts/Helvetica.ttf) format("truetype");
  font-weight: 400;
  font-style: normal;
  font-display: swap; }

h1, h2, h3, h4, h5 {
  letter-spacing: 0.3px; }

body {
  font-family: 'Helvetica', sans-serif !important; }

nav {
  position: absolute;
  width: 100%;
  top: 0;
  z-index: 10;
  padding-top: 25px; }
  nav img {
    width: 70%; }

header {
  background: #01243D;
  color: #fff;
  padding: 12px;
  text-transform: uppercase;
  position: relative;
  z-index: 2;
  width: 100%; }
  header button {
    background: #0EB9B1;
    padding: 10px;
    border-radius: 7px;
    color: #fff;
    border: 0;
    text-transform: uppercase;
    margin-left: 25px; }

.root {
  background: #0EB9B1;
  height: auto;
  min-height: 100vh;
  padding: 60px 0;
  transition: all .3s;
  position: relative;
  display: flex;
  align-items: center; }
  .root .flat {
    width: 800px;
    height: 350px;
    background: #002E4E;
    position: absolute;
    transform: rotate(-56deg) translateX(-134px) translateY(-355px);
    top: 0; }
  .root .box {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(2, 1fr);
    grid-column-gap: 15px;
    grid-row-gap: 15px;
    margin-top: 110px; }
    .root .box div {
      background: #fff;
      text-align: center;
      padding: 23px 15px;
      border-radius: 10px; }
      .root .box div h5 {
        font-size: 16px;
        font-weight: bold;
        font-family: 'Helvetica Bold'; }
      .root .box div p {
        font-size: 14px;
        min-height: 65px;
        margin-bottom: 5px; }
      .root .box div button {
        background: #0EB9B1;
        padding: 7px 18px;
        font-size: 15px;
        border-radius: 7px;
        color: #fff;
        border: 0;
        text-transform: uppercase; }
  .root .image {
    position: relative; }
    .root .image img {
      position: absolute;
      z-index: 3;
      transform: scale(1.5);
      transition: all .3s; }
  .root #triangulo {
    width: 0;
    height: 0;
    border-left: 450px solid transparent;
    border-right: 450px solid transparent;
    border-bottom: 800px solid #002E4E;
    position: absolute;
    bottom: 0;
    right: 0;
    z-index: 1; }

/* paginas */
.page {
  height: auto;
  padding: 110px 0;
  position: relative; }
  .page.blog {
    background: #064470;
    padding: 0;
    padding-top: 100px; }
    .page.blog .flat {
      transform: rotate(-56deg) translateX(-108px) translateY(-550px); }
    .page.blog h1 {
      text-transform: capitalize;
      font-size: 6.7rem; }
    .page.blog p {
      font-size: 1.35rem; }
    .page.blog .mt-5 {
      margin-top: 130px !important; }
    .page.blog .owl-nav {
      text-align: left; }
      .page.blog .owl-nav button:hover {
        background: transparent; }
        .page.blog .owl-nav button:hover svg path {
          fill: #064470; }
    .page.blog .blog .item {
      position: relative; }
      .page.blog .blog .item .image {
        overflow: hidden;
        width: 100%;
        height: 590px; }
        .page.blog .blog .item .image img {
          width: 100%;
          height: 100%;
          object-fit: cover; }
      .page.blog .blog .item .title-post {
        position: absolute;
        z-index: 1;
        bottom: 0;
        left: 0;
        width: 55%;
        padding: 25px; }
        .page.blog .blog .item .title-post h2 {
          color: #FFF;
          font-weight: 500;
          font-size: 2rem; }
          .page.blog .blog .item .title-post h2 a {
            color: #FFF; }
      .page.blog .blog .item::after {
        content: '';
        width: 100%;
        height: 100%;
        background: black;
        background: linear-gradient(180deg, rgba(0, 0, 0, 0) 28%, rgba(0, 0, 0, 0.695116) 100%);
        position: absolute;
        left: 0;
        top: 0; }
    .page.blog::after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 100px;
      background: #FFF; }
  .page.pesquisa-de-mercado {
    background: #54A440; }
    .page.pesquisa-de-mercado .flat {
      transform: rotate(-56deg) translateX(-134px) translateY(-550px); }
      .page.pesquisa-de-mercado .flat.two {
        transform: rotate(45deg) translateX(-200px) translateY(180px); }
    .page.pesquisa-de-mercado button {
      background: #0EB9B1;
      border: 0;
      color: #fff;
      padding: 10px 15px;
      border-radius: 4px;
      font-weight: 600; }
  .page.outsourcing {
    background: #891BCD; }
    .page.outsourcing .flat {
      background: #54137C;
      transform: rotate(-56deg) translateX(-134px) translateY(-550px); }
    .page.outsourcing h1 {
      font-size: 3.3rem; }
    .page.outsourcing p {
      font-size: 1.3rem; }
    .page.outsourcing button {
      background: #0EB9B1;
      border: 0;
      color: #fff;
      padding: 10px 15px;
      border-radius: 4px;
      font-weight: 600; }
  .page.gestao-municipal {
    background: #C9CD1B;
    min-height: 100vh;
    display: flex;
    align-items: center; }
    .page.gestao-municipal .flat {
      transform: rotate(-56deg) translateX(-134px) translateY(-650px); }
    .page.gestao-municipal h1 {
      font-size: 5rem;
      font-family: 'Helvetica Bold';
      line-height: 105%; }
    .page.gestao-municipal button {
      background: #FFF;
      border: 0;
      color: #0EB9B1;
      padding: 15px;
      border-radius: 4px;
      font-weight: 600; }
    .page.gestao-municipal .form {
      position: relative;
      z-index: 2;
      background: #002E4E;
      padding: 50px 30px; }
      .page.gestao-municipal .form h5 {
        color: #FFF;
        font-size: 1.8rem;
        font-weight: 400;
        margin-bottom: 25px;
        font-family: 'Helvetica Bold'; }
      .page.gestao-municipal .form label {
        color: #FFF; }
      .page.gestao-municipal .form button {
        width: 70%;
        padding: 15px 24px;
        background: #0EB9B1;
        border: 0;
        color: #FFF;
        border-radius: 3px;
        margin-top: 10px; }
    .page.gestao-municipal #triangulo {
      width: 0;
      height: 0;
      border-left: 450px solid transparent;
      border-right: 450px solid transparent;
      border-bottom: 800px solid #002E4E;
      position: absolute;
      bottom: 0;
      right: 0;
      z-index: 1; }
  .page.eleitoral {
    background: #0EB9B1;
    min-height: 100vh;
    display: flex;
    align-items: center; }
    .page.eleitoral .flat {
      transform: rotate(-56deg) translateX(-108px) translateY(-630px); }
    .page.eleitoral h1 {
      font-size: 5.4rem;
      font-family: 'Helvetica Bold'; }
    .page.eleitoral button {
      background: #FFF;
      border: 0;
      color: #0EB9B1;
      padding: 15px;
      border-radius: 4px;
      font-weight: 600; }
    .page.eleitoral #triangulo {
      width: 0;
      height: 0;
      border-left: 450px solid transparent;
      border-right: 450px solid transparent;
      border-bottom: 800px solid #002E4E;
      position: absolute;
      bottom: 0;
      right: 0;
      z-index: 1; }
  .page .flat {
    width: 800px;
    height: 350px;
    background: #002E4E;
    position: absolute;
    transform: rotate(-56deg) translateX(-134px) translateY(-355px); }
  .page h1 {
    color: #FFF;
    font-weight: bold;
    font-family: 'Helvetica Bold';
    font-size: 2.8rem; }
  .page p {
    color: #fff;
    font-size: 1.2rem; }

.post-blog.single {
  background: #064470;
  padding-top: 55px;
  position: relative; }
  .post-blog.single .logo {
    margin-bottom: 35px; }
  .post-blog.single .bread {
    margin-bottom: 20px;
    margin-top: 40px; }
    .post-blog.single .bread ul {
      display: flex;
      padding: 0;
      margin: 0; }
      .post-blog.single .bread ul li {
        list-style: none;
        color: #FFF;
        margin: 5px;
        text-transform: uppercase;
        font-family: 'Helvetica Light'; }
        .post-blog.single .bread ul li a {
          color: #FFF;
          font-family: 'Helvetica'; }
          .post-blog.single .bread ul li a:hover {
            text-decoration: none; }
  .post-blog.single .image {
    width: 100%;
    height: 350px;
    overflow: hidden;
    position: relative;
    z-index: 1; }
    .post-blog.single .image img {
      width: 100%;
      height: 100%;
      object-fit: cover; }
  .post-blog.single::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100px;
    background: #FFF; }

.blog-post h5 {
  color: #002E4E;
  font-size: 2rem;
  font-weight: 600;
  margin-bottom: 15px;
  font-family: 'Helvetica Bold'; }

.blog-post .post {
  margin-bottom: 30px; }
  .blog-post .post .img {
    width: 100%;
    height: 190px;
    overflow: hidden; }
    .blog-post .post .img img {
      object-fit: cover;
      width: 100%;
      height: 100%; }
  .blog-post .post .title-post h2 {
    font-size: 1.3rem;
    text-transform: uppercase; }
    .blog-post .post .title-post h2 a {
      color: #002E4E; }
      .blog-post .post .title-post h2 a:hover {
        text-decoration: none; }
  .blog-post .post .title-post p {
    color: #6C6C6C; }

#pageDefault {
  margin-top: 55px; }
  #pageDefault h1 {
    font-family: 'Helvetica Bold'; }
  #pageDefault .share ul {
    display: flex;
    margin-bottom: 25px; }
    #pageDefault .share ul li {
      list-style: none; }
      #pageDefault .share ul li button {
        background: transparent;
        border: 0; }
  #pageDefault .list-pages ul {
    padding: 0;
    margin: 0; }
    #pageDefault .list-pages ul li {
      border: 1px #D9D9D9 solid;
      border-radius: 5px;
      padding: 25px;
      list-style: none;
      margin-bottom: 15px; }
      #pageDefault .list-pages ul li h5 {
        color: #000000;
        text-transform: uppercase;
        font-size: 17px;
        font-family: 'Helvetica Bold'; }
      #pageDefault .list-pages ul li p {
        color: #000000;
        font-size: 16px;
        margin-bottom: 5px; }
      #pageDefault .list-pages ul li button {
        background: #0EB9B1;
        color: #FFF;
        border: 0;
        border-radius: 4px;
        padding: 5px 13px;
        font-size: 14px; }

#pagination {
  display: flex;
  justify-content: center;
  margin: 25px 0; }
  #pagination button {
    background: #FFF;
    border: 1px #E3E6E8 solid;
    width: 30px;
    height: 30px;
    margin: 5px;
    color: #002E4E; }
  #pagination span.current button {
    background: #002E4E;
    color: #FFF; }

section.slide {
  padding: 85px 0; }
  section.slide h2 {
    color: #002E4E; }
  section.slide.blue {
    background: #002E4E; }
    section.slide.blue h2 {
      color: #FFF; }
    section.slide.blue .pesquisa-de-mercado .card h5 {
      font-size: 15px !important;
      letter-spacing: 0; }
    section.slide.blue .pesquisa-de-mercado .card button {
      background: #002E4E;
      color: #FFF;
      border-radius: 5px;
      padding: 2px 10px 5px;
      border: 0; }
    section.slide.blue .pesquisa-de-mercado .owl-nav button svg path {
      fill: #fff; }
  section.slide .pesquisa-de-mercado .card {
    position: relative; }
    section.slide .pesquisa-de-mercado .card h5 {
      font-size: 16px !important; }
    section.slide .pesquisa-de-mercado .card button {
      background: #002E4E;
      color: #FFF;
      border-radius: 5px;
      padding: 2px 10px 5px;
      border: 0;
      position: absolute;
      width: 125px;
      left: 0;
      bottom: 10px;
      right: 0;
      margin: 0 auto; }
  section.slide.roxo {
    background: #54137C;
    padding-top: 0;
    position: relative; }
    section.slide.roxo.simulacao {
      padding-top: 0;
      background: #891BCD; }
      section.slide.roxo.simulacao .simulacao {
        background: #54137C;
        padding-top: 80px;
        padding-bottom: 80px;
        border-radius: 20px; }
        section.slide.roxo.simulacao .simulacao .selecao {
          padding: 25px;
          text-align: center; }
          section.slide.roxo.simulacao .simulacao .selecao .grid {
            display: grid;
            grid-template-columns: repeat(4, 1fr);
            grid-template-rows: repeat(1, 1fr);
            grid-column-gap: 15px;
            grid-row-gap: 15px; }
            section.slide.roxo.simulacao .simulacao .selecao .grid .select {
              position: relative; }
              section.slide.roxo.simulacao .simulacao .selecao .grid .select input[type="checkbox"] {
                position: absolute;
                opacity: 0;
                cursor: pointer;
                height: 0;
                width: 0; }
              section.slide.roxo.simulacao .simulacao .selecao .grid .select input:checked ~ .checkmark {
                background-color: #0EB9B1; }
                section.slide.roxo.simulacao .simulacao .selecao .grid .select input:checked ~ .checkmark .title {
                  color: #fff; }
                section.slide.roxo.simulacao .simulacao .selecao .grid .select input:checked ~ .checkmark .desc {
                  color: #fff; }
                section.slide.roxo.simulacao .simulacao .selecao .grid .select input:checked ~ .checkmark .act div {
                  border: 1px #54137C solid;
                  color: #fff;
                  background: #54137C; }
                  section.slide.roxo.simulacao .simulacao .selecao .grid .select input:checked ~ .checkmark .act div.enabled {
                    display: block; }
                  section.slide.roxo.simulacao .simulacao .selecao .grid .select input:checked ~ .checkmark .act div.disabled {
                    display: none; }
                section.slide.roxo.simulacao .simulacao .selecao .grid .select input:checked ~ .checkmark .counter input {
                  color: #FFF; }
              section.slide.roxo.simulacao .simulacao .selecao .grid .select .checkmark {
                height: auto;
                width: 100%;
                background-color: #eee;
                border-radius: 10px;
                cursor: pointer;
                padding: 0 30px;
                min-height: 180px;
                display: flex;
                align-items: center;
                justify-content: center; }
                section.slide.roxo.simulacao .simulacao .selecao .grid .select .checkmark .text {
                  padding: 20px 0; }
                  section.slide.roxo.simulacao .simulacao .selecao .grid .select .checkmark .text .title {
                    color: #54137C;
                    font-size: 20px;
                    font-weight: bold; }
                  section.slide.roxo.simulacao .simulacao .selecao .grid .select .checkmark .text .desc {
                    color: #54137C;
                    font-size: 16px; }
                  section.slide.roxo.simulacao .simulacao .selecao .grid .select .checkmark .text .act {
                    margin-top: 3px; }
                    section.slide.roxo.simulacao .simulacao .selecao .grid .select .checkmark .text .act div {
                      border: 1px #54137C solid;
                      padding: 6px 9px;
                      border-radius: 5px;
                      width: fit-content;
                      margin: 0 auto;
                      color: #54137C;
                      font-size: 15px;
                      font-weight: 500; }
                      section.slide.roxo.simulacao .simulacao .selecao .grid .select .checkmark .text .act div.enabled {
                        display: none; }
                  section.slide.roxo.simulacao .simulacao .selecao .grid .select .checkmark .text .counter {
                    display: flex;
                    align-items: center; }
                    section.slide.roxo.simulacao .simulacao .selecao .grid .select .checkmark .text .counter input {
                      width: 20px;
                      height: 20px;
                      margin: 5px;
                      border: 0;
                      background: transparent; }
                    section.slide.roxo.simulacao .simulacao .selecao .grid .select .checkmark .text .counter button {
                      width: 30px;
                      height: 30px;
                      margin-top: 0;
                      padding: 0;
                      display: flex;
                      justify-content: center; }
          section.slide.roxo.simulacao .simulacao .selecao button {
            padding: 9px 24px;
            background: #0EB9B1;
            border: 0;
            color: #FFF;
            border-radius: 3px;
            margin-top: 25px; }
        section.slide.roxo.simulacao .simulacao.email .selecao form {
          width: 50%;
          margin: 0 auto; }
          section.slide.roxo.simulacao .simulacao.email .selecao form label {
            color: #FFF;
            font-size: 15px;
            margin-bottom: 5px; }
          section.slide.roxo.simulacao .simulacao.email .selecao form p {
            font-size: 15px;
            margin-top: 15px; }
          section.slide.roxo.simulacao .simulacao.email .selecao form input {
            background: #FFF;
            border: 0;
            border-radius: 3px;
            padding: 9px 12px;
            width: 70%;
            margin-bottom: 10px; }
            section.slide.roxo.simulacao .simulacao.email .selecao form input:focus {
              outline: none; }
          section.slide.roxo.simulacao .simulacao.email .selecao form button {
            padding: 9px 24px;
            background: #0EB9B1;
            border: 0;
            color: #FFF;
            border-radius: 3px;
            margin-top: 5px; }
        section.slide.roxo.simulacao .simulacao.email .selecao .box {
          background: #891BCD;
          width: 70%;
          margin: 0 auto;
          border-radius: 10px;
          padding: 22px; }
          section.slide.roxo.simulacao .simulacao.email .selecao .box h5 {
            font-size: 16px;
            text-transform: uppercase; }
          section.slide.roxo.simulacao .simulacao.email .selecao .box span {
            color: #FFF;
            font-size: 16px; }
        section.slide.roxo.simulacao .simulacao h5 {
          color: #FFF;
          font-weight: bold;
          font-size: 3rem; }
        section.slide.roxo.simulacao .simulacao p {
          color: #FFF;
          font-size: 1.2rem; }
      section.slide.roxo.simulacao::before {
        display: none; }
    section.slide.roxo .content {
      padding: 0; }
      section.slide.roxo .content .face-a-face article {
        background: #FFF;
        padding: 20px;
        margin-bottom: 25px;
        min-height: 430px; }
        section.slide.roxo .content .face-a-face article h5 {
          color: #891BCD;
          font-weight: bold;
          font-size: 1.5rem;
          margin-top: 10px; }
        section.slide.roxo .content .face-a-face article p {
          color: #6C6C6C;
          font-size: 1rem; }
      section.slide.roxo .content .face-a-face.menor article {
        min-height: 365px; }
    section.slide.roxo .tab-slide {
      overflow: hidden; }
      section.slide.roxo .tab-slide .vantagens {
        /* .owl-stage-outer{
                        overflow: auto;
                    }
                    .owl-stage{
                        overflow: auto;
                    } */ }
        section.slide.roxo .tab-slide .vantagens .owl-item {
          background: #891BCD;
          opacity: .8;
          transition: all .3s;
          border-radius: 5px;
          padding: 10px;
          -webkit-box-shadow: 2px 2px 12px -1px rgba(0, 0, 0, 0.24);
          -moz-box-shadow: 2px 2px 12px -1px rgba(0, 0, 0, 0.24);
          box-shadow: 2px 2px 12px -1px rgba(0, 0, 0, 0.24);
          margin-top: 45px;
          margin-bottom: 40px;
          min-height: 135px;
          display: flex;
          align-items: center; }
          section.slide.roxo .tab-slide .vantagens .owl-item.center {
            opacity: 1 !important;
            background: #FFF !important;
            transform: scale(1.1); }
            section.slide.roxo .tab-slide .vantagens .owl-item.center .bolet {
              background: #891BCD !important;
              color: #fff !important; }
            section.slide.roxo .tab-slide .vantagens .owl-item.center .text p {
              color: #002E4E !important; }
          section.slide.roxo .tab-slide .vantagens .owl-item .card {
            background: transparent;
            display: flex;
            flex-direction: row;
            align-items: center;
            border: 0; }
            section.slide.roxo .tab-slide .vantagens .owl-item .card .bolet {
              background: #FFF;
              color: #891BCD;
              width: 47px;
              height: 47px;
              display: flex;
              align-items: center;
              justify-content: center;
              border-radius: 50%;
              font-size: 23px;
              font-weight: bold; }
            section.slide.roxo .tab-slide .vantagens .owl-item .card .text {
              margin-left: 15px;
              width: 80%; }
              section.slide.roxo .tab-slide .vantagens .owl-item .card .text p {
                margin-bottom: 0;
                color: #FFF; }
        section.slide.roxo .tab-slide .vantagens .owl-nav button {
          background: #891BCD;
          width: 30px;
          height: 30px; }
          section.slide.roxo .tab-slide .vantagens .owl-nav button svg path {
            fill: #fff; }
      section.slide.roxo .tab-slide .cta {
        text-align: center;
        margin-top: 25px; }
        section.slide.roxo .tab-slide .cta button {
          background: #0EB9B1;
          color: #FFF;
          border: 0;
          padding: 11px 15px 11px;
          border-radius: 3px;
          font-family: 'Helvetica Bold'; }
    section.slide.roxo h2 {
      color: #FFF;
      font-family: 'Helvetica Bold';
      font-size: 2.3rem; }
    section.slide.roxo::before {
      content: '';
      top: 0;
      position: absolute;
      left: 0;
      width: 100%;
      height: 100px;
      background: #891BCD; }
  section.slide.gestao {
    padding-top: 0 !important;
    padding-bottom: 0;
    transform: translateY(-80px);
    position: relative;
    z-index: 9; }
    section.slide.gestao .card h5 {
      font-size: 17px !important; }
    section.slide.gestao h2 {
      font-weight: bold;
      color: #002E4E; }
    section.slide.gestao ul {
      padding: 0;
      margin: 0;
      margin-left: 22px; }
      section.slide.gestao ul strong {
        font-weight: bold;
        font-size: 1.5rem;
        color: #002E4E; }
      section.slide.gestao ul li {
        list-style: none;
        margin-bottom: 10px;
        background: #002E4E;
        color: #FFF;
        padding: 5px;
        width: fit-content;
        border-radius: 7px;
        max-width: 320px; }
    section.slide.gestao .ebook {
      background: #002E4E;
      padding: 40px 0; }
      section.slide.gestao .ebook h5 {
        color: #0EB9B1;
        font-size: 2.5rem;
        font-weight: 700; }
      section.slide.gestao .ebook p, section.slide.gestao .ebook label {
        color: #FFF; }
      section.slide.gestao .ebook label {
        margin-bottom: 0; }
      section.slide.gestao .ebook span {
        color: #FFF;
        font-size: 13px; }
      section.slide.gestao .ebook form input {
        padding: 12px;
        height: auto;
        border: 0; }
        section.slide.gestao .ebook form input::-webkit-input-placeholder {
          /* Edge */
          color: #A8ABBA; }
        section.slide.gestao .ebook form input:-ms-input-placeholder {
          /* Internet Explorer 10-11 */
          color: #A8ABBA; }
        section.slide.gestao .ebook form input::placeholder {
          color: #A8ABBA; }
      section.slide.gestao .ebook form button {
        background: #0EB9B1;
        color: #FFF;
        border: 0;
        border-radius: 3px;
        padding: 13px 25px;
        margin-bottom: 15px; }
    section.slide.gestao.big .card h5 {
      font-size: 45px !important;
      font-weight: 600; }
  section.slide .pesquisa-de-mercado {
    display: flex;
    align-items: center; }
    section.slide .pesquisa-de-mercado .item {
      padding: 8px; }
      section.slide .pesquisa-de-mercado .item a {
        color: #002E4E; }
        section.slide .pesquisa-de-mercado .item a:hover {
          text-decoration: none; }
    section.slide .pesquisa-de-mercado .card {
      min-height: 210px;
      border-radius: 11px;
      display: flex;
      align-items: center;
      justify-content: center;
      -webkit-box-shadow: 2px 2px 12px -1px rgba(0, 0, 0, 0.24);
      -moz-box-shadow: 2px 2px 12px -1px rgba(0, 0, 0, 0.24);
      box-shadow: 2px 2px 12px -1px rgba(0, 0, 0, 0.24);
      padding: 20px; }
      section.slide .pesquisa-de-mercado .card img {
        width: 50px;
        margin: 0 auto 6px; }
      section.slide .pesquisa-de-mercado .card h5 {
        text-transform: uppercase;
        font-size: 37px;
        color: #002E4E;
        font-weight: 500; }
      section.slide .pesquisa-de-mercado .card p {
        text-transform: uppercase;
        font-size: 15px; }
    section.slide .pesquisa-de-mercado .owl-nav {
      width: 108%;
      position: absolute;
      display: flex;
      justify-content: space-between;
      left: -33px; }
      section.slide .pesquisa-de-mercado .owl-nav button svg path {
        fill: #002E4E; }
      section.slide .pesquisa-de-mercado .owl-nav button:hover {
        background: transparent; }
  section.slide h2 {
    font-size: 2rem;
    font-family: 'Helvetica Bold'; }

section.slider-content {
  background: #54A440;
  padding: 85px 0;
  overflow: hidden; }
  section.slider-content .flat {
    width: 800px;
    height: 350px;
    background: #002E4E;
    position: absolute;
    transform: rotate(55deg) translateX(190px) translateY(535px); }
  section.slider-content .content {
    background: #FFF;
    padding: 40px;
    position: relative;
    z-index: 2; }
    section.slider-content .content .slide .owl-nav {
      display: flex; }
      section.slider-content .content .slide .owl-nav button svg path {
        fill: #002E4E; }
      section.slider-content .content .slide .owl-nav button:hover {
        background: transparent; }
    section.slider-content .content .item h5 {
      color: #54A440;
      font-weight: bold;
      margin-bottom: 15px;
      font-size: 1.7rem;
      text-transform: uppercase; }
    section.slider-content .content .item p {
      color: #6C6C6C; }
  section.slider-content h3 {
    color: #FFF;
    font-size: 2.5rem;
    font-family: 'Helvetica Bold';
    font-weight: bold; }
  section.slider-content p {
    color: #FFF;
    font-size: 1.1rem; }

section.estudo-de-casos {
  position: relative;
  z-index: 2;
  padding: 75px 0 0; }
  section.estudo-de-casos.roxo {
    background: #54137C; }
    section.estudo-de-casos.roxo.clear::after {
      background: #0EB9B1;
      height: 100px; }
    section.estudo-de-casos.roxo h5 {
      color: #FFF; }
    section.estudo-de-casos.roxo::after {
      content: '';
      position: absolute;
      width: 100%;
      height: 65px;
      bottom: 0;
      background: #FFF; }
  section.estudo-de-casos.blue {
    background: #002E4E; }
    section.estudo-de-casos.blue.clear::after {
      background: #0EB9B1;
      height: 100px; }
    section.estudo-de-casos.blue h5 {
      color: #FFF; }
    section.estudo-de-casos.blue::after {
      content: '';
      position: absolute;
      width: 100%;
      height: 65px;
      bottom: 0;
      background: #FFF; }
  section.estudo-de-casos.green {
    background: #C9CD1B !important; }
    section.estudo-de-casos.green h5 {
      color: #FFF;
      font-size: 2.8rem; }
  section.estudo-de-casos.white .nav-blog button svg path {
    fill: #002E4E;
    transition: all .3s; }
  section.estudo-de-casos.white::after {
    content: '';
    position: absolute;
    width: 100%;
    height: 65px;
    bottom: 0;
    background: #54A440; }
  section.estudo-de-casos .blog, section.estudo-de-casos .casos {
    margin-top: 15px;
    position: relative;
    z-index: 1; }
    section.estudo-de-casos .blog .item, section.estudo-de-casos .casos .item {
      background: #FFF;
      padding: 25px;
      -webkit-box-shadow: 2px 2px 12px -1px rgba(0, 0, 0, 0.24);
      -moz-box-shadow: 2px 2px 12px -1px rgba(0, 0, 0, 0.24);
      box-shadow: 2px 2px 12px -1px rgba(0, 0, 0, 0.24);
      border-radius: 5px; }
      section.estudo-de-casos .blog .item .title, section.estudo-de-casos .casos .item .title {
        min-height: 70px; }
        section.estudo-de-casos .blog .item .title h5, section.estudo-de-casos .casos .item .title h5 {
          margin-bottom: 0;
          font-family: 'Helvetica'; }
      section.estudo-de-casos .blog .item .content, section.estudo-de-casos .casos .item .content {
        min-height: 70px; }
      section.estudo-de-casos .blog .item .image, section.estudo-de-casos .casos .item .image {
        width: 100%;
        height: 180px;
        overflow: hidden; }
        section.estudo-de-casos .blog .item .image img, section.estudo-de-casos .casos .item .image img {
          object-fit: cover;
          width: 100%;
          height: 100%; }
      section.estudo-de-casos .blog .item h5, section.estudo-de-casos .casos .item h5 {
        color: #0EB9B1;
        font-size: 1.2rem;
        font-weight: 400;
        text-transform: uppercase;
        margin-top: 15px; }
        section.estudo-de-casos .blog .item h5 a, section.estudo-de-casos .casos .item h5 a {
          color: #0EB9B1; }
          section.estudo-de-casos .blog .item h5 a:hover, section.estudo-de-casos .casos .item h5 a:hover {
            text-decoration: none; }
      section.estudo-de-casos .blog .item p, section.estudo-de-casos .casos .item p {
        font-size: 14px; }
  section.estudo-de-casos h5 {
    font-weight: bold;
    font-family: 'Helvetica Bold';
    font-size: 2.8rem; }
  section.estudo-de-casos .nav-blog button, section.estudo-de-casos .nav-casos button {
    background: transparent !important;
    width: 30px;
    height: 40px;
    border: 0;
    display: flex !important;
    align-items: center;
    justify-content: center;
    margin: 0;
    padding: 0;
    transition: all .3s; }
    section.estudo-de-casos .nav-blog button svg path, section.estudo-de-casos .nav-casos button svg path {
      fill: #FFF;
      transition: all .3s; }
    section.estudo-de-casos .nav-blog button::after, section.estudo-de-casos .nav-casos button::after {
      display: none; }

section.vantagens {
  background: #54A440;
  padding: 85px 0;
  position: relative;
  overflow: hidden; }
  section.vantagens h5 {
    color: #FFF;
    font-size: 2.8rem !important;
    font-family: 'Helvetica Bold'; }
  section.vantagens.verde {
    color: #FFF; }
  section.vantagens.blue {
    color: #FFF;
    background: #0EB9B1; }
  section.vantagens.roxo {
    color: #FFF;
    background: #891BCD; }
  section.vantagens.green {
    background: #C9CD1B !important; }
    section.vantagens.green h5 {
      color: #FFF;
      font-size: 2rem; }
  section.vantagens .content {
    background: #FFF;
    padding: 40px;
    margin-top: 20px;
    position: relative;
    z-index: 2; }
    section.vantagens .content .vantagens-slide .owl-nav {
      display: flex; }
      section.vantagens .content .vantagens-slide .owl-nav button svg path {
        fill: #6C6C6C; }
      section.vantagens .content .vantagens-slide .owl-nav button:hover {
        background: transparent; }
    section.vantagens .content .item h5 {
      color: #393939;
      font-weight: bold;
      margin-bottom: 15px;
      font-size: 2rem !important; }
      section.vantagens .content .item h5 span {
        font-size: 2.5rem;
        margin-right: 15px; }
    section.vantagens .content .item p {
      color: #6C6C6C;
      font-size: .9rem; }
  section.vantagens h5 {
    font-weight: bold;
    font-size: 1.5rem; }
  section.vantagens #triangulo {
    width: 0;
    height: 0;
    border-left: 400px solid transparent;
    border-right: 400px solid transparent;
    border-bottom: 700px solid #002E4E;
    position: absolute;
    bottom: 0;
    right: 240px;
    z-index: 1; }
  section.vantagens #flat {
    width: 0;
    height: 0;
    border-left: 0 solid transparent;
    border-right: 670px solid transparent;
    border-bottom: 700px solid #002E4E;
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: 1; }

section.form {
  background: #002E4E;
  padding: 85px 0; }
  section.form ul {
    padding: 0;
    margin: 0;
    margin-top: 35px; }
    section.form ul li {
      background: #FFF;
      list-style: none;
      width: fit-content;
      padding: 2px 7px;
      border-radius: 5px;
      margin-bottom: 11px; }
      section.form ul li img {
        width: 25px; }
  section.form.roxo {
    background: #891BCD; }
    section.form.roxo label {
      color: #fff; }
    section.form.roxo hr {
      border-bottom: 1px #0EB9B1 solid;
      margin: 0px 0 10px; }
    section.form.roxo h5 {
      font-size: 1.7rem; }
    section.form.roxo p {
      color: #FFF;
      font-size: 1.1rem; }
    section.form.roxo select {
      font-family: 'Helvetica Light'; }
    section.form.roxo textarea {
      height: 80px;
      font-family: 'Helvetica Light';
      color: #A8ABBA; }
    section.form.roxo input {
      font-family: 'Helvetica Light'; }
      section.form.roxo input::-webkit-input-placeholder {
        /* Edge */
        color: #A8ABBA;
        font-family: 'Helvetica Light'; }
      section.form.roxo input:-ms-input-placeholder {
        /* Internet Explorer 10-11 */
        color: #A8ABBA;
        font-family: 'Helvetica Light'; }
      section.form.roxo input::placeholder {
        color: #A8ABBA;
        font-family: 'Helvetica Light'; }
    section.form.roxo textarea {
      font-family: 'Helvetica Light'; }
      section.form.roxo textarea::-webkit-input-placeholder {
        /* Edge */
        color: #A8ABBA;
        font-family: 'Helvetica Light'; }
      section.form.roxo textarea:-ms-input-placeholder {
        /* Internet Explorer 10-11 */
        color: #A8ABBA;
        font-family: 'Helvetica Light'; }
      section.form.roxo textarea::placeholder {
        color: #A8ABBA;
        font-family: 'Helvetica Light'; }
  section.form h5 {
    color: #FFF;
    font-size: 2rem;
    font-weight: bold;
    font-family: 'Helvetica Bold'; }
  section.form form label {
    color: #FFF; }
  section.form form select {
    padding: 10px;
    height: auto;
    border-radius: 3px;
    color: #A8ABBA; }
  section.form form textarea {
    height: 70px;
    background: #FFF !important;
    text-align: left !important;
    color: #000 !important; }
    section.form form textarea::-webkit-input-placeholder {
      /* Edge */
      color: #A8ABBA;
      font-family: 'Helvetica';
      text-align: left; }
    section.form form textarea:-ms-input-placeholder {
      /* Internet Explorer 10-11 */
      color: #A8ABBA;
      font-family: 'Helvetica';
      text-align: left; }
    section.form form textarea::placeholder {
      color: #A8ABBA;
      font-family: 'Helvetica';
      text-align: left; }
  section.form form input {
    padding: 10px;
    height: auto;
    border-radius: 3px; }
    section.form form input::-webkit-input-placeholder {
      /* Edge */
      color: #A8ABBA; }
    section.form form input:-ms-input-placeholder {
      /* Internet Explorer 10-11 */
      color: #A8ABBA; }
    section.form form input::placeholder {
      color: #A8ABBA; }
  section.form form button {
    background: #0EB9B1;
    border: 0;
    width: 100%;
    padding: 11px;
    color: #FFF;
    border-radius: 5px; }

section.cta {
  padding: 85px 0; }
  section.cta h5 {
    color: #FFF;
    font-size: 2rem;
    font-weight: bold; }
  section.cta.roxo {
    margin-top: 35px;
    background: #891BCD; }
    section.cta.roxo button {
      background: #0EB9B1;
      border: 0;
      padding: 10px 20px;
      color: #FFF;
      border-radius: 3px;
      margin-top: 15px; }

form input::-webkit-input-placeholder {
  /* Edge */
  color: #A8ABBA; }

form input:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #A8ABBA; }

form input::placeholder {
  color: #A8ABBA; }

.conteudoRetorno {
  color: #FFF;
  border: 1px #FFF solid;
  margin-bottom: 5px;
  padding: 5px;
  border-radius: 5px; }

/*antigo*/
.clientes {
  min-height: 500px !important; }

.clientes {
  background-image: url(../img/banner_clientes.png) !important;
  padding: 0 !important;
  background-size: inherit !important;
  background-position: center center !important;
  display: flex;
  align-items: center;
  text-align: center; }

.client {
  margin: 12px 0;
  transition: all .4s;
  cursor: pointer; }

.client img {
  width: 85% !important;
  height: auto !important; }

.result:hover .client {
  opacity: .5; }

.result .client:hover {
  opacity: 1; }

.contato h1 {
  color: #FFF !important; }

#post-49 h3 {
  color: #004168;
  font-size: 1.5em; }

#pageDefault #post-49 p {
  color: #008E90;
  font-weight: bold;
  margin-top: 16px; }

#pageDefault #post-49 h2 {
  color: #004168 !important;
  font-size: 1em !important; }

#post-49 input, optgroup, textarea {
  background: #174d70 !important;
  border: 0 !important;
  border-radius: 3px !important;
  width: 100%;
  color: #FFF !important;
  text-align: center !important;
  font-size: 15px !important; }

#post-49 .form-control:focus {
  box-shadow: inherit !important; }

#post-49 .btn-primary:focus {
  box-shadow: inherit !important; }

#post-49 form .btn-primary {
  transition: all .3s;
  background: #0a9395  !important; }

#post-49 form .btn-primary:hover {
  transform: translateY(-2px);
  -webkit-box-shadow: 4px 4px 25px -5px rgba(0, 145, 181, 0.49);
  -moz-box-shadow: 4px 4px 25px -5px rgba(0, 145, 181, 0.49);
  box-shadow: 4px 4px 25px -5px rgba(0, 145, 181, 0.49); }

#post-49 form ::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  color: #FFF;
  text-align: center;
  font-size: 15px; }

#post-49 form ::-moz-placeholder {
  /* Firefox 19+ */
  color: #FFF;
  text-align: center;
  font-size: 15px; }

#post-49 form :-ms-input-placeholder {
  /* IE 10+ */
  color: #FFF;
  text-align: center;
  font-size: 15px; }

#post-49 form :-moz-placeholder {
  /* Firefox 18- */
  color: #FFF;
  text-align: center;
  font-size: 15px; }

.empresa, .contato, .clientes {
  color: #fff;
  padding: 8% 0 0;
  line-height: 3em;
  font-family: 'Helvetica Bold'; }

.contato h1,
.empresa h1, .clientes h1 {
  font-size: 2em; }

.contato p,
.empresa p, .clientes p {
  line-height: 140%;
  font-family: 'Helvetica'; }

.contato {
  color: #FFF;
  background-position: inherit !important;
  min-height: 500px;
  display: flex;
  align-items: center; }

.contato {
  background-image: url(../img/banner_contato.png) !important;
  padding: 0 !important; }

.empresa {
  background-image: url(../img/banner_empresa.png) !important;
  padding: 0 !important;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center; }

.historico {
  background: #0a9395;
  padding: 5% 0; }

.historico p {
  color: #FFF;
  text-align: justify;
  font-size: .95em;
  line-height: 155%; }

.historico h4 {
  color: #FFF;
  font-size: 2em; }

.valores .col1 {
  background: #194e71;
  color: #FFF;
  padding: 5% 3% 5% 15px;
  text-align: justify; }

.valores .col1 p {
  font-size: .9em;
  margin: 0; }

.valores .col1 ul {
  padding: 20px !important; }

.valores .col2 {
  background: #3a4652;
  color: #FFF;
  padding: 5% 15px 5% 5%;
  text-align: justify; }

.valores .col2 p {
  font-size: .9em;
  margin: 0; }

@media (max-width: 992px) {
  nav img {
    width: 30%; }
  .logo {
    margin: 0 auto; }
  header button {
    margin-top: 10px;
    margin-left: 0; }
  .root {
    height: auto;
    padding: 50px 0 15px; }
    .root .box {
      display: flex;
      flex-direction: column;
      margin-top: 15px; }
      .root .box div {
        padding: 30px 30px;
        width: 80%;
        margin: 0 auto; }
    .root .flat {
      width: 300px;
      height: 200px;
      transform: rotate(-60deg) translateX(-20px) translateY(-236px); }
    .root .image {
      display: none; }
    .root #triangulo {
      display: none; }
  .page.eleitoral #triangulo {
    border-right: 550px solid transparent;
    border-bottom: 740px solid #002E4E; } }

footer {
  background: #002E4E;
  padding: 50px 0 0; }
  footer ul {
    width: 100%;
    padding: 0;
    margin: 0; }
    footer ul strong {
      color: #0EB9B1;
      font-size: 1.2rem; }
    footer ul li {
      list-style: none; }
      footer ul li a {
        color: #FFF; }
        footer ul li a:hover {
          color: #FFF;
          text-decoration: none; }
  footer .social ul {
    display: flex;
    align-items: center;
    margin-top: 10px; }
    footer .social ul li {
      margin-right: 20px; }
  footer .line {
    background: #01243D;
    text-transform: uppercase;
    font-family: 'Helvetica Light';
    padding-bottom: 10px; }
    footer .line ul {
      display: flex;
      justify-content: flex-end; }
      footer .line ul li {
        color: #FFF;
        padding: 13px 20px;
        font-size: 15px;
        font-family: 'Helvetica Light'; }
        footer .line ul li.active {
          background: #0EB9B1; }
          footer .line ul li.active a {
            color: #01243D; }
    footer .line a {
      color: #FFF; }
      footer .line a:hover {
        text-decoration: none; }

@media (min-width: 993px) and (max-width: 1800px) {
  .root .flat {
    transform: rotate(-61deg) translateX(-152px) translateY(-513px); }
  .root .image img {
    transform: scale(1.1); }
  .root #triangulo {
    border-left: 300px solid transparent;
    border-right: 300px solid transparent;
    border-bottom: 600px solid #002E4E;
    right: 105px; }
  .page.blog .blog .item .image {
    height: 460px; } }

@media (max-width: 992px) {
  html, body {
    max-width: 100%;
    overflow-x: hidden; }
  .page {
    padding: 10px 0; }
    .page.gestao-municipal {
      text-align: center;
      min-height: auto;
      padding-bottom: 0; }
      .page.gestao-municipal h1 {
        font-size: 2.5rem; }
      .page.gestao-municipal img {
        width: 50%; }
      .page.gestao-municipal #triangulo {
        display: none; }
      .page.gestao-municipal .flat {
        transform: rotate(-56deg) translateX(100px) translateY(-815px); }
    .page.pesquisa-de-mercado {
      text-align: center;
      min-height: auto;
      padding-bottom: 55px; }
      .page.pesquisa-de-mercado .flat.two {
        display: none; }
      .page.pesquisa-de-mercado h1 {
        font-size: 2.5rem; }
      .page.pesquisa-de-mercado img {
        width: 80%; }
    .page.outsourcing {
      padding-bottom: 55px; }
      .page.outsourcing img {
        width: 80%; }
      .page.outsourcing h1 {
        font-size: 2.8rem; }
    .page.blog .mt-5 {
      margin-top: 5px !important; }
    .page.blog .blog .item .image {
      height: 290px; }
    .page.blog .blog .item .title-post {
      width: 100%;
      padding: 15px; }
  .post-blog.single .image {
    height: 215px; }
  .post-blog.single .bread {
    margin-top: 80px; }
    .post-blog.single .bread ul {
      display: block; }
  #pageDefault .share ul {
    margin-bottom: 25px;
    padding-left: 0; }
  #pageDefault img {
    width: 100%;
    height: auto; }
  section.slide.gestao {
    transform: translateY(0px);
    padding: 55px 0 !important; }
    section.slide.gestao .d-flex {
      flex-direction: column; }
      section.slide.gestao .d-flex ul {
        margin-left: 0;
        margin-top: 25px; }
  section.slide {
    padding: 50px 0; }
    section.slide .pesquisa-de-mercado {
      display: block; }
      section.slide .pesquisa-de-mercado .owl-nav {
        width: 80px;
        position: relative;
        left: inherit;
        margin: 0 auto; }
      section.slide .pesquisa-de-mercado .card h5 {
        font-size: 15px !important; }
    section.slide.gestao.big .card h5 {
      font-size: 36px !important;
      font-weight: 600; }
    section.slide.gestao .ebook {
      padding: 40px 20px; }
    section.slide.roxo .tab-slide .vantagens .owl-item .card .bolet {
      position: relative;
      left: 8px; }
    section.slide.roxo.simulacao .simulacao .selecao {
      padding: 10px; }
    section.slide.roxo.simulacao .simulacao .selecao .grid {
      display: grid;
      grid-template-columns: repeat(2, 1fr); }
      section.slide.roxo.simulacao .simulacao .selecao .grid .checkmark {
        padding: 0 10px !important; }
    section.slide.roxo.simulacao .simulacao.email {
      padding: 20px; }
      section.slide.roxo.simulacao .simulacao.email form {
        width: 100% !important; }
        section.slide.roxo.simulacao .simulacao.email form input {
          width: 100% !important; }
      section.slide.roxo.simulacao .simulacao.email .selecao .box {
        width: 100%; }
  section.slider-content .flat {
    display: none; }
  section.estudo-de-casos .justify-content-around {
    justify-content: space-between !important; }
  section.estudo-de-casos h5 {
    font-size: 2.2rem; }
  section.estudo-de-casos.green h5 {
    font-size: 2.2rem; }
  section.vantagens h5 {
    font-size: 2.2rem !important; }
  footer .logo {
    text-align: center; }
  footer .social {
    margin-top: 10px; }
    footer .social ul {
      justify-content: center; }
      footer .social ul li {
        margin-right: 0;
        margin: 5px 10px; }
  footer .line {
    padding-top: 35px;
    text-align: center; }
    footer .line ul {
      display: block;
      margin-top: 10px; }
  footer ul {
    margin-top: 35px;
    text-align: center; }
  .owl-nav {
    margin: 25px auto 0 !important; }
    .owl-nav button svg {
      width: 27px !important;
      height: 27px; }
  .next, .prev {
    margin-right: 15px !important; }
    .next svg, .prev svg {
      width: 27px !important;
      height: 27px; } }

@media (min-width: 768px) and (max-width: 991.98px) {
  html, body {
    max-width: 100%;
    overflow-x: hidden; } }
